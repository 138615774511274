@use 'modules/styles/meta/index' as meta;

.reql-force-decision-button {
    &__small-button {
        width: 36px;
        height: 36px;
        fill: #384650;
        cursor: pointer;
        user-select: none;
        @include meta.u-flexCenter();

        &:disabled {
            @include meta.o-disabled();
        }

        &:hover:not(:disabled) {
            background: #e0e2e3;
        }

        &:focus:not(:disabled),
        &:active:not(:disabled) {
            background: #d5d4d4;
        }
    }

    &__large-button {
        display: flex;
    }

    &__large-button-icon {
        fill: currentColor;
        margin-right: 8px;
    }

    &__panel {
        display: flex;
        flex-flow: column;
        position: relative;
        @include meta.o-dropdown-panel;
    }

    &__tip-panel {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid #ebeaea;
        width: 250px;
    }

    &__tip-panel-title {
        margin: 10px 15px 5px 15px;
        @include meta.font-standard-semibold(12px, #000);
    }

    &__tip-panel-description {
        margin-bottom: 10px;
        margin-left: 15px;
        margin-right: 15px;
        @include meta.font-standard(12px, #565656);
    }

    &__menu-list {
        display: flex;
        flex-flow: column;
        min-width: 160px;
    }

    &__menu-item {
        display: flex;
        align-items: center;
        height: 33px;
        padding: 0 15px;
        cursor: pointer;
        @include meta.font-standard(12px, #000);

        &--regular {
            padding: 0 10px;
        }

        &:disabled {
            opacity: 0.4;
            cursor: default;
        }

        &:not(:disabled):hover {
            background: #f5f5f5;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #ebeaea;
        }
    }

    &__close-button {
        cursor: pointer;
        position: absolute;
        top: 3px;
        right: 5px;
        padding: 10px;
        fill: #777d81;
    }
}
