@use 'sass:color';
@use 'modules/styles/meta/index' as meta;

.common-ui-license-on-grace-popup {
    height: 600px;
    width: 670px;
    position: relative;
    display: flex;
    flex-flow: column;

    &__head {
        background: #384650;
        padding: 60px 55px 0 55px;
        height: 245px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    &__title {
        text-align: center;
        margin-top: 20px;
        @include meta.font-standard-semibold(16px, #fff);
    }

    &__close-icon {
        position: absolute;
        left: 35px;
        top: 20px;
        z-index: 1;
        fill: currentColor;
        color: #fff;
        padding: 10px;

        :not(.disabled) > &:hover {
            color: color.adjust(#fff, $lightness: -25%);
        }
    }

    &__body {
        padding: 10px 55px 30px 55px;
        display: flex;
        flex-flow: column;
        overflow-y: auto;
        flex: 1;
    }

    &__action-section {
        text-transform: uppercase;
        height: 60px;
        display: flex;
        align-items: center;
        flex: none;
        @include meta.font-standard-semibold(14px, #000);
    }

    &__action-list {
        display: flex;
        flex-flow: column;
    }

    &__action-item {
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid #eaeaea;
    }

    &__action-item-text {
        flex: 1;
        margin-right: 10px;
        @include meta.font-standard(13px, #000);
        @include meta.u-text-ellipsis;
    }

    &__action-item-link {
        @include meta.o-transparent-link;
    }
}
