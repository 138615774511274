@use '../../styles/meta/index' as meta;

.ui-or-separator {
    display: flex;
    align-items: center;

    &__or-separator-line {
        flex: 1;
        height: 1px;
        @include meta.o-popup-border-bottom;
    }

    &__or-separator-text {
        flex: none;
        padding: 0 22px;
        @include meta.font-standard(13px, #6c6a6a);
    }
}
