@use '../../styles/meta/index' as meta;
@use 'index' as index;

.ui-data-table-checkbox-cell {
    @include index.data-row-cell-padding();

    &__checkbox {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
