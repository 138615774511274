@use '../../../styles/meta/index' as meta;

.form-dropdown-editor-no-filter-button--t-form {
    &__default-no-filter-content {
        display: flex;
        align-items: center;
        padding: 3px 5px;
        overflow: hidden;
        @include meta.o-form-input;
        & {
            @include meta.font-standard(14px, #333);
        }

        &--disabled {
            padding-right: 7px;
        }
    }

    &__text {
        flex: 1;
        @include meta.u-text-ellipsis;

        &--placeholder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #a3a3a3;
        }
    }

    &__dropdown-icon {
        flex: none;
        margin-left: 5px;
        margin-top: 1px;
        visibility: hidden;
        transition: rotate 0.05s;
    }

    &:not(&--disabled) &__dropdown-icon {
        visibility: visible;

        &--visible {
            rotate: x 180deg;
        }
    }
}
