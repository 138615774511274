@use 'modules/styles/meta/index' as meta;

.pending-practice-invitation {
    width: 820px;

    &__content {
        padding: 10px 60px 10px 80px;
        overflow: hidden auto;
    }

    &__business-accounts-text {
        margin-bottom: 5px;
        @include meta.font-standard-bold(13px);
    }

    &__what-happend-after-accepting-text {
        margin-bottom: 18px;
        @include meta.font-standard(12px, #5e5c5c);
    }

    &__radio_item {
        height: 60px;
        flex-direction: row-reverse;
        &:not(:last-of-type) {
            border-bottom: 1px solid #cacccd;
        }

        &--decline {
            line-height: 20px;
            @include meta.font-standard-bold(14px, #de0202);
        }
    }

    &__already-added-to-account {
        display: flex;
        align-items: center;
        background-color: #f3f3f3;
        padding: 20px;
        color: #5e5c5c;
        margin-bottom: 10px;
    }

    &__warning-icon {
        margin-right: 16px;
    }
}
