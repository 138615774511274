@use '../../styles/meta/index' as meta;

.ui-loading-spinner {
    display: flex;
    flex-flow: column;
    align-items: center;

    &__text {
        padding: 10px 0 0;
        @include meta.font-standard(13px, #333);
    }

    &__spinner {
        display: inline-block;
        position: relative;
        width: 28px;
        height: 28px;
    }

    &--size-small12 &__spinner {
        width: 12px;
        height: 12px;
    }

    &__spinner-container {
        animation: container-rotate 1568ms linear infinite;
        width: 100%;
        height: 100%;
        direction: ltr;
        display: inline-block;
        position: relative;
        white-space: nowrap;
    }

    &__spinner-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        white-space: nowrap;
        display: flex;
        border-color: #4caf50;

        &--layer-1 {
            animation:
                fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }

        &--layer-2 {
            animation:
                fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }

        &--layer-3 {
            animation:
                fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }

        &--layer-4 {
            animation:
                fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
                layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }
    }

    &--theme-white &__spinner-layer {
        border-color: #fff;
    }

    &__circle-clipper {
        display: inline-block;
        position: relative;
        width: 50%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;
    }

    &__gap-patch {
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 45%;
        width: 10%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;
        display: inline-block;
    }

    &__circle {
        display: inline-block;
        box-sizing: border-box;
        height: 100%;
        border-width: 3px;
        border-style: solid;
        border-color: inherit;
        border-bottom-color: transparent !important;
        border-radius: 50%;
        animation: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &--left {
            animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            border-right-color: transparent !important;
            transform: rotate(129deg);
            width: 200%;
        }

        &--gap-patch {
            width: 1000%;
            left: -450%;
        }

        &--right {
            animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            left: -100%;
            border-left-color: transparent !important;
            transform: rotate(-129deg);
            width: 200%;
        }
    }

    &--size-small12 &__circle {
        border-width: 2px;
    }
}

@keyframes container-rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes fill-unfill-rotate {
    12.5% {
        transform: rotate(135deg);
    }

    25% {
        transform: rotate(270deg);
    }

    37.5% {
        transform: rotate(405deg);
    }

    50% {
        transform: rotate(540deg);
    }

    62.5% {
        transform: rotate(675deg);
    }

    75% {
        transform: rotate(810deg);
    }

    87.5% {
        transform: rotate(945deg);
    }

    to {
        transform: rotate(1080deg);
    }
}

@keyframes layer-1-fade-in-out {
    from {
        opacity: 0.99;
    }

    25% {
        opacity: 0.99;
    }

    26% {
        opacity: 0;
    }

    89% {
        opacity: 0;
    }

    90% {
        opacity: 0.99;
    }

    100% {
        opacity: 0.99;
    }
}

@keyframes layer-2-fade-in-out {
    from {
        opacity: 0;
    }

    15% {
        opacity: 0;
    }

    25% {
        opacity: 0.99;
    }

    50% {
        opacity: 0.99;
    }

    51% {
        opacity: 0;
    }
}

@keyframes layer-3-fade-in-out {
    from {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    50% {
        opacity: 0.99;
    }

    75% {
        opacity: 0.99;
    }

    76% {
        opacity: 0;
    }
}

@keyframes layer-4-fade-in-out {
    from {
        opacity: 0;
    }

    65% {
        opacity: 0;
    }

    75% {
        opacity: 0.99;
    }

    90% {
        opacity: 0.99;
    }

    100% {
        opacity: 0;
    }
}

@keyframes left-spin {
    from {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(130deg);
    }
}

@keyframes right-spin {
    from {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
    to {
        transform: rotate(-130deg);
    }
}
