@use 'sass:color';
@use '../../styles/meta/index';

.ui-checkbox {
    position: relative;
    border: 2px solid #4c8a5c;
    border-radius: 1px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &--size-18 {
        width: 18px;
        height: 18px;
    }

    &--disabled {
        cursor: default;
        border-color: #a0a1a1;
        opacity: 0.7;
    }

    &--invalid {
        background-color: #fff5f3;
        border-color: #f99178;
    }

    &--checked:not(&--disabled) {
        background: #4c8a5c;
    }
    &--checked#{&}--disabled {
        background: #a0a1a1;
    }

    &--checked::before {
        position: absolute;
        content: '';
        width: 50%;
        height: 86%;
        border: none;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform-origin: 80% 79%;
        transform: rotate(45deg);
    }

    &--tri-state::before {
        position: absolute;
        content: '';
        left: 20%;
        top: 20%;
        width: 60%;
        height: 60%;
        background: #4c8a5c;
    }

    &--tri-state#{&}--disabled::before {
        background: #a0a1a1;
    }

    &:focus {
        box-shadow: 0 0 1px 2px color.adjust(#4c8a5c, $lightness: 30%);
    }
}
