@use '../../../styles/meta/index';

.form-radio-group-item--t-no-layout {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--disabled {
        cursor: default;
    }

    &__radiobox {
        margin-right: 12px;
    }

    &__content {
        flex: 1 0 auto;
        line-height: 18px;
    }
}

.form-radio-group-item--t-form {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--disabled {
        cursor: default;
    }

    &__radiobox {
        margin-right: 12px;
    }

    &__content {
        flex: 1 auto;
        line-height: 18px;
    }
}
