@use '../../styles/meta/index' as meta;

body {
    @include meta.if-mobile-device {
        // iOS Safari does that automatically (unfortunately)
        // Others - don't
        overflow: auto;
    }
}

.ui-popup {
    &__popup-portal {
        @include meta.if-mobile-device {
            display: inline-block;
        }

        @include meta.u-posAbsolute($left: 0, $top: 0, $right: 0, $bottom: 0);
    }

    &__popup {
        @include meta.u-posAbsolute($left: 0, $top: 0, $right: 0, $bottom: 0);
        overflow: hidden;

        &--active .ui-default-popup-content {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
