@use '../../styles/meta/index';

.ui-data-table {
    display: flex;
    flex-flow: column;

    &__table-head {
        display: flex;
        flex: none;
        height: 50px;
        background: #f9f9f9;
    }

    &__table-body-wrp {
        overflow: hidden;
        flex: 1;
    }

    &__table-body {
        display: flex;
        flex-flow: column;
    }

    &__column-header {
        flex: 1 0 50px;
        display: flex;
        align-items: center;

        &--fixed {
            flex: none;
        }
    }

    &__load-more-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
    }
}
