@use '../../styles/meta/index';

.ui-link {
    color: #4c8a5c;
    cursor: pointer;

    &:hover,
    &:focus {
        color: #4c8a5c;
        text-decoration: underline;
    }
}
