@use '../styles/meta/index';

.auto-grow-input {
    &__hidden-div {
        position: absolute;
        top: -99999px;
        left: -99999px;
        width: auto;
        padding: 0;
        white-space: pre;
    }
}
