@use '../../styles/meta/index';

@keyframes ui-loading-bar--loading-animation {
    from {
        left: -200px;
        width: 30%;
    }
    50% {
        width: 30%;
    }
    70% {
        width: 70%;
    }
    80% {
        left: 50%;
    }
    95% {
        left: 120%;
    }
    to {
        left: 100%;
    }
}

.ui-loading-bar {
    position: relative;
    z-index: 1;

    &__content {
        height: 4px;
        left: 0;
        right: 0;
        background-color: #ddd;
        overflow: hidden;
        position: relative;

        &--absolute {
            position: absolute;
        }
    }

    &__bar {
        position: absolute;
        left: -200px;
        width: 200px;
        height: 4px;
        background-color: #4c8a5c;
        animation: ui-loading-bar--loading-animation 2s linear infinite;
    }

    &--aqua {
        background-color: #ddd;
    }

    &--aqua &__bar {
        background-color: #19b9e2;
    }
}
