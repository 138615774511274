@use 'modules/styles/meta/index' as meta;

.pending-practice-invitation-row {
    height: 60px;
    flex-direction: row-reverse;

    &__text {
        display: flex;
        flex-direction: column;

        &--disabled {
            color: #5e5c5c;
        }
    }

    &__company-name {
        line-height: 20px;
        @include meta.font-standard-bold(14px);
    }

    &__invitation-owner {
        line-height: 16px;
        @include meta.font-standard(12px);
    }
}
