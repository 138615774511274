@use 'modules/styles/meta/index' as meta;

$side-margin: 60px;

.reql-fields-section {
    display: flex;
    flex-flow: row wrap;
    padding: 0 $side-margin - 10px 0 $side-margin;
    margin-bottom: 20px;

    & > * {
        margin-right: 10px;
        margin-bottom: 10px;
        min-width: 120px;
        max-width: 100%;
        flex: 1;
    }
}
