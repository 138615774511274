@use 'sass:color';
@use 'modules/styles/meta/index' as meta;

.reql-activity-item {
    position: relative;
    min-height: 35px;
    padding-left: 50px;

    &--right {
        padding-left: 0;
        padding-right: 50px;
    }

    &__icon {
        cursor: pointer;
        @include meta.u-posAbsolute($left: 0, $top: 15px);

        &--system {
            top: 0;
        }

        &--rounded {
            border-radius: 50%;
            overflow: hidden;
        }

        &--unclickable {
            cursor: default;
        }
    }

    &--right &__icon {
        left: auto;
        right: 0;
    }

    &__author-name-row {
    }

    &--right &__author-name-row {
        text-align: right;
    }

    &__author-name {
        display: inline;
        cursor: pointer;
        user-select: none;
        @include meta.font-standard-semibold(12px, #999);
    }

    &__message-row {
        white-space: pre-line;

        & > * {
            display: block;
        }
    }

    &--right &__message-row {
        text-align: right;
    }

    &__comment-row {
        margin-top: 5px;
        margin-bottom: 3px;
        display: flex;
        align-items: flex-end;
    }

    &--right &__comment-row {
        flex-direction: row-reverse;
    }

    &__comment-text {
        overflow: hidden;
        white-space: pre-line;
        padding: 11px 15px;
        background: #efeded;
        border-radius: 0 12px 12px 12px;
        line-height: 1.66;
        @include meta.font-standard(12px, #182026);

        a {
            color: color.adjust(#478a60, $lightness: -10%);

            &:hover {
                color: color.adjust(#478a60, $lightness: -10%);
            }
        }
    }

    &--right &__comment-text {
        border-radius: 12px 0 12px 12px;
    }

    &__comment-date {
        flex: none;
        margin-bottom: 10px;
        margin-left: 6px;
        @include meta.font-standard(10px, #868686);
    }

    &--right &__comment-date {
        margin-left: 0;
        margin-right: 6px;
    }

    &__event-date {
        @include meta.font-standard(10px, #868686);
    }

    &--right &__event-date {
        text-align: right;
    }

    &__comment-files-row {
        display: flex;
        flex-flow: column;
        width: 295px;
        margin-top: 5px;
        border-radius: 0 12px 0 12px;
        border: solid 1px #dfdfdf;
        padding: 5px 10px;
    }

    &--right &__comment-files-row {
        border-radius: 12px 0 12px 0;
        margin-left: auto;
    }

    &__comment-file-item {
        display: flex;
        align-items: center;
        height: 24px;
        border-radius: 4px;
        padding: 0 2px 0 6px;
        overflow: hidden;
        cursor: pointer;

        &--deleting {
            opacity: 0.5;
        }

        &:not(&--deleting):hover {
            background: #f3f3f3;
        }
    }

    &__comment-file-icon {
        fill: currentColor;
        color: #a0a1a1;
        margin-right: 5px;
        margin-top: 1px;
    }

    &__comment-file-item:not(&__comment-file-item--deleting):hover &__comment-file-icon {
        color: #4c8a5c;
    }

    &__comment-file-text {
        margin-right: 5px;
        flex: 1;
        @include meta.font-standard(12px, #000);
        @include meta.u-text-ellipsis;
    }

    &__comment-file-item:not(&__comment-file-item--deleting):hover &__comment-file-text {
        @include meta.font-standard(12px, #477753);
    }

    &__comment-file-delete-button {
        fill: currentColor;
        color: #cacccd;
        margin: 5px;
    }

    &__comment-file-item:not(&__comment-file-item--deleting):hover &__comment-file-delete-button {
        color: #384650;
    }
}
