@use 'modules/styles/meta/index' as meta;

.first-start-account-setup-popup {
    &__first-start-popup {
        width: 840px;
        min-height: 480px;
        max-width: 95%;
        max-height: 95%;
        display: flex;
        flex-flow: column;
        position: relative;
    }

    &__standalone-popup {
        width: 840px;
        min-height: 380px;
        max-width: 95%;
        max-height: 95%;
        display: flex;
        flex-flow: column;
    }

    &__standalone-content {
        display: flex;
        flex-flow: column;
        position: relative;
    }

    &__standalone-loading {
        opacity: 0;
        transition: opacity 200ms ease-in-out;

        &--active {
            opacity: 1;
        }
    }

    &__standalone-body {
        overflow: hidden;
        flex: 1;
        padding: 0 60px 30px 80px;
    }

    &__nav-bar {
        flex: none;
    }

    &__body {
        overflow: hidden;
        overflow-y: auto; // TODO: change design to make popup smaller.
        flex: 1;
        padding: 20px 60px 30px 60px;
    }

    &__info-panel {
        display: flex;
        flex-flow: column;
        width: 50%;
        margin-top: 10px;
    }

    &__info-partner-radio {
        margin-top: 3px;
        @include meta.font-standard(12px, #000);
    }

    &__info-fields {
        margin-top: 25px;

        & > :not(:first-child) {
            margin-top: 15px;
        }
    }

    &__connect-panel {
        display: flex;
        flex-flow: column;
    }

    &__connect-description {
        @include meta.font-standard(13px, #000);
    }

    &__connect-tiles {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    &__or-separator-block {
        display: flex;
        align-items: center;
        margin: 0;
    }

    &__or-separator-line {
        flex: 1;
        height: 1px;
        @include meta.o-popup-border-bottom;
    }

    &__or-separator-text {
        flex: none;
        padding: 0 22px;
        @include meta.font-standard(13px, #6c6a6a);
    }

    &__connect-actions {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
}
