@use '../../../../styles/meta/index' as meta;

.ui-default-popup-content {
    width: 640px;
    max-width: 95%;
    max-height: 95%;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    cursor: default;
    user-select: none;
    overflow-y: auto;

    &__header {
        flex: none;
        display: flex;
        align-items: center;
        height: 80px;
        padding-right: 60px;

        &--sticky {
            position: sticky;
            transition: box-shadow 200ms ease-in-out;
            top: 0;
            z-index: 1;
            background-color: #fff;
        }

        &--active-sticky {
            box-shadow:
                0px 4px 10px rgba(4, 5, 46, 0.09),
                0px 4px 30px rgba(4, 5, 46, 0.12);
        }

        &--border-botom {
            @include meta.o-popup-border-bottom;
        }
    }

    &__header-close {
        flex: none;
        width: 80px;
        display: flex;
        justify-content: center;
    }

    &__header-close-button {
        cursor: pointer;
    }

    &__header-title {
        line-height: 1.4;
        flex: 1;
        @include meta.font-standard-semibold(20px, #333);
        @include meta.u-text-ellipsis;
    }

    &__header-buttons {
        flex: none;
        display: flex;
        align-items: center;

        > * {
            margin-left: 12px;
        }
    }

    &__close-icon {
        fill: currentColor;
        color: #777;
        margin: 10px;

        :not(.disabled) > &:hover {
            color: #9b3117;
        }
    }
}
