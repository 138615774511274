@use 'sass:color';
// See react-dates/lib/css/_datepicker.css
.DayPicker .CalendarDay {
    &__highlighted_calendar {
        background: #fff;
        border: 1px double #4c8a5c;
        color: #484848;
        &:active,
        &:hover {
            background: color.adjust(#4c8a5c, $lightness: 50%); //#e4e7e7;
            border: 1px double #4c8a5c;
            color: #484848;
        }
    }

    &__selected,
    &__selected:active,
    &__selected:hover {
        background: #4c8a5c;
        border: 1px double #4c8a5c;
        color: #fff;
    }

    &:focus {
        border: 1px double #4c8a5c;
        background: color.adjust(#4c8a5c, $lightness: 50%);
        color: #484848;
    }
}
