@use 'modules/styles/meta/index' as meta;

.common-ui-user-picker {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 100%;

    &--with-clear-button {
        max-width: calc(100% - 14px);
    }

    &__dropdown-editor {
        flex: 1;

        &--align-to-avatar {
            margin-left: 12px;
        }
    }

    &__selected-value-content {
        display: flex;
        align-items: center;
    }

    &__avatar {
        margin-right: 10px;
        flex: none;

        &--disabled {
            fill: #a0a1a1;
        }
    }

    &__text {
        margin-right: 5px;
        white-space: normal;
        @include meta.font-standard(12px, #000);
        @include meta.u-text-ellipsis;

        span {
            display: inline;
            white-space: nowrap;
        }

        span + span:before {
            content: ' ';
        }

        &--disabled {
            color: #a0a1a1;
        }
    }

    &__clear-button {
        flex: none;
        fill: #eaeaea;
        border-radius: 50%;
        background: #a3a2a2;
        padding: 3px;
        opacity: 0;
        transition:
            opacity 200ms,
            fill 200ms;
        line-height: 0;

        &:hover {
            background: #ee6f3a;
            fill: #fff;
        }

        svg {
            display: block;
        }
    }

    &:hover &__clear-button {
        opacity: 1;
    }

    &__add-icon {
        fill: #4c8a5c;
        margin-right: 16px;
        display: flex;

        &--align-to-avatar {
            width: 34px;
            padding-left: 12px;
        }

        &--small {
            width: 34px;
            padding-left: 5px;
            margin-right: 1px;
        }

        &--error {
            fill: #cb4e30;
        }

        &--disabled {
            fill: #a0a1a1;
        }
    }

    &__select-button {
        @include meta.font-standard-semibold(13px, #4c8a5c);

        &--error {
            @include meta.font-standard-semibold(13px, #a3341a);
        }

        &--disabled {
            @include meta.font-standard-semibold(13px, #a0a1a1);
        }
    }
}
