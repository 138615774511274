@use '../../styles/meta/index' as meta;

.ui-tooltip {
    &__panel {
        max-width: 200px;
        margin: 3px auto;
        display: flex;
        flex-flow: column-reverse;
        align-items: center;
        opacity: 0;
        transition: opacity 200ms ease-in-out;
        cursor: default;

        &--active {
            opacity: 0.95;
        }
    }

    &__panel-arrow {
        z-index: 1;
        border-width: 5px 5px 0;
        border-style: solid;
        border-color: transparent;
        border-top-color: #464646 !important;
    }

    &__panel-content {
        padding: 3px 8px;
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
        background: #464646;
        max-width: 100%;
        overflow: hidden;
        text-align: center;
        @include meta.font-standard(12px, #fff);
    }
}
