@use '../../../styles/meta/index' as meta;

.form-dropdown-editor-multi-value-button--t-form {
    display: flex;
    flex-flow: wrap;
    position: relative;
    @include meta.o-form-input();

    &--loading::after {
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArklEQVQ4y2NgwAGYmJgkgNRdEIaySQOMjIxeQOo/CEPZBMFCIP4KVBwC4rCysrIAqXYQhrJhBtswMzNzYDPgK9TG+QQsegwyBJuTQTbPB9IqJPuXIgAKYVAgIfuTVHAX6vd2YjUAA5EPSB0GWuxGuQHU8AJ1ALHRCHI20NVC2OQIJiRooIHUzMYmj5KUoRrCQBjGh9o8GyhmR9BLQMVyUNv+Q9mkAWisrAZhfDEEAA5cIiN3OTV6AAAAAElFTkSuQmCC');
        animation: form-dropdown-editor-button__loader-spin-360 1000ms infinite linear;
        @include meta.u-posAbsolute($top: 6px, $right: 7px, $width: 16px, $height: 16px);
    }

    &__item {
        margin: 2px 4px 2px 0;
    }

    &__input {
        height: 16px;
        margin: 2px 0;
        line-height: 16px;
        color: #000;
        font-size: 13px;
        max-width: 100%;
        @include meta.u-transparent-input();

        &::-ms-clear {
            display: none;
        }
    }

    &--disabled &__input {
        color: #a0a1a1;
    }

    &--invalid &__input {
        color: #923924;

        &::placeholder {
            color: #923924;
        }
    }

    &--warning &__input {
        color: #ffc107;

        &::placeholder {
            color: #ffc107;
        }
    }
}

.form-dropdown-editor-multi-value-button--t-matrix {
    padding: 1px 0;
    display: flex;
    flex-flow: wrap;
    position: relative;
    border-bottom: 1px solid transparent;
    @include meta.o-transparent-input();

    &:not(&--disabled):not(&--invalid):not(&--warning):not(&--focused):hover {
        border-bottom: 1px solid #cacaca;
    }

    &--focused:not(&--invalid):not(&--warning) {
        border-bottom: 1px solid #4c8b5d;
    }

    &--invalid {
        border-bottom: 1px solid #f99178;
    }

    &--warning {
        border-bottom: 1px solid #ffc107;
    }

    &--loading::after {
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArklEQVQ4y2NgwAGYmJgkgNRdEIaySQOMjIxeQOo/CEPZBMFCIP4KVBwC4rCysrIAqXYQhrJhBtswMzNzYDPgK9TG+QQsegwyBJuTQTbPB9IqJPuXIgAKYVAgIfuTVHAX6vd2YjUAA5EPSB0GWuxGuQHU8AJ1ALHRCHI20NVC2OQIJiRooIHUzMYmj5KUoRrCQBjGh9o8GyhmR9BLQMVyUNv+Q9mkAWisrAZhfDEEAA5cIiN3OTV6AAAAAElFTkSuQmCC');
        animation: form-dropdown-editor-button__loader-spin-360 1000ms infinite linear;
        @include meta.u-posAbsolute($top: 3px, $right: 7px, $width: 16px, $height: 16px);
    }

    &__item {
        margin: 2px 4px 2px 0;
    }

    &__input {
        height: 16px;
        margin: 2px 0;
        line-height: 16px;
        color: #000;
        font-size: 13px;
        max-width: 100%;
        @include meta.u-transparent-input();
    }

    &--disabled &__input {
        color: #a0a1a1;
    }

    &--invalid &__input {
        color: #923924;

        &::placeholder {
            color: #923924;
        }
    }

    &--warning &__input {
        color: #ffc107;

        &::placeholder {
            color: #ffc107;
        }
    }
}

.form-dropdown-editor-multi-value-button--t-transparent {
    padding: 1px 0;
    display: flex;
    flex-flow: wrap;
    position: relative;
    @include meta.o-transparent-input();

    &--loading::after {
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArklEQVQ4y2NgwAGYmJgkgNRdEIaySQOMjIxeQOo/CEPZBMFCIP4KVBwC4rCysrIAqXYQhrJhBtswMzNzYDPgK9TG+QQsegwyBJuTQTbPB9IqJPuXIgAKYVAgIfuTVHAX6vd2YjUAA5EPSB0GWuxGuQHU8AJ1ALHRCHI20NVC2OQIJiRooIHUzMYmj5KUoRrCQBjGh9o8GyhmR9BLQMVyUNv+Q9mkAWisrAZhfDEEAA5cIiN3OTV6AAAAAElFTkSuQmCC');
        animation: form-dropdown-editor-button__loader-spin-360 1000ms infinite linear;
        @include meta.u-posAbsolute($top: 3px, $right: 7px, $width: 16px, $height: 16px);
    }

    &__item {
        margin: 2px 4px 2px 0;
    }

    &__input {
        height: 16px;
        margin: 2px 0;
        line-height: 16px;
        color: #000;
        font-size: 13px;
        max-width: 100%;
        @include meta.u-transparent-input();
    }

    &--disabled &__input {
        color: #a0a1a1;
    }

    &--invalid &__input {
        color: #923924;

        &::placeholder {
            color: #923924;
        }
    }

    &--warning &__input {
        color: #ffc107;

        &::placeholder {
            color: #ffc107;
        }
    }
}
