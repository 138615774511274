@use 'modules/styles/meta/index' as meta;

.reql-field-entry {
    display: flex;
    flex-flow: column;

    &__field {
        margin-bottom: 3px;
        @include meta.font-standard-semibold(11px, #777d81);
        @include meta.u-text-ellipsis;
    }

    &__value {
        white-space: pre-line;
        @include meta.font-standard(13px, #000);

        &--warning {
            color: #b53415;
            font-weight: 600;
        }
    }

    &__warning-text {
        padding-top: 5px;
        max-width: 150px;
        @include meta.font-standard(11px, #000);
    }

    &__rich-editor {
        border: 1px solid #cacccd;
        padding: 5px;
        margin: 5px 0;
    }
}
