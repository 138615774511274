@use 'modules/styles/meta/index' as meta;

.form-dropdown-editor-user-list-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    border-bottom: 1px solid #ebeaea;

    &:hover {
        background: #f5f5f5;
    }

    &--active {
        background: #f5f5f5;
    }

    &__text {
        flex: 1;
        line-height: 18px;
        padding-left: 10px;
        @include meta.u-text-ellipsis();
        @include meta.font-standard(12px, #000);
    }

    &__highlighted-text {
        background-color: #edecd0;
    }
}
