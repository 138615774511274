@use 'modules/styles/meta/index' as meta;

.first-start-nav-bar {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 60px;
    @include meta.o-popup-border-bottom();

    &__left-block {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;
    }

    &__title {
        @include meta.font-standard(14px, #000);
    }

    &__action-button {
        margin-left: 20px;
        flex: none;
    }

    &__back-button {
        margin-left: 20px;
        flex: none;
    }
}
