@use 'modules/styles/meta/index' as meta;

.reql-xero-line-items-section {
    padding: 0 60px;

    &__card-field-unit-price {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    &__card-field-unit-price-indicator {
        margin-left: 3px;
        border-radius: 50%;
        width: 5px;
        height: 5px;

        &--yellow {
            background: #ffe688;
        }

        &--orange {
            background: #f0984f;
        }

        &--red {
            background: #cb4e30;
        }
    }

    &__footer {
        display: flex;
    }

    &__footer-left-block {
        flex: 1;
    }

    &__exchange-rate {
        @include meta.font-standard-semibold(11px, #565656);
    }

    &__exchange-rate-currency {
        display: inline;
        color: #000;
    }

    &__external-block {
        margin-top: 30px;
    }

    &__rb-external-url {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__rb-external-url-link {
        display: flex;
        align-items: center;
        @include meta.o-transparent-link();
    }

    &__rb-external-url-link-text {
        margin-left: 5px;
        margin-bottom: 2px;
        @include meta.font-standard(13px, #000);
    }

    &__footer-total-block {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
    }

    &__footer-amounts-tax-type {
        margin-bottom: 15px;
        @include meta.font-standard-semibold(11px, #777d81);
    }
}
