@use '../../styles/meta/index' as meta;

.ui-action-menu {
    &__panel {
        padding: 5px 0;
        min-width: 160px;
        font-size: 14px;
        @include meta.o-dropdown-panel();
    }
}
