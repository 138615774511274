@use 'modules/styles/meta/index';

.reql-activity-section {
    display: flex;
    flex-flow: column;

    &__expand-button-wrp {
        margin-bottom: 6px;
        text-align: center;
    }

    &__expand-button {
    }

    &__items {
        display: flex;
        flex-flow: column;
        padding-bottom: 25px;

        & > *:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}
