@use 'modules/styles/meta/index' as meta;

.int-xero-integration-select-popup {
    width: 840px;
    height: 520px;

    &__content {
        display: flex;
        flex-flow: column;
        @include meta.o-popup-padding;
        & {
            padding-bottom: 60px;
        }
    }

    &__message {
        @include meta.font-standard-semibold(13px, #000);
    }

    &__warning {
        margin-top: 20px;
        padding: 15px 20px 15px 22px;
        display: flex;
        align-items: center;
        background: #f3f3f3;
    }

    &__warning-icon {
        margin-right: 15px;
    }

    &__warning-text {
        @include meta.font-standard(13px, #000);
    }

    &__xero-org-block {
        margin-top: 30px;
    }
}
