@use 'modules/styles/meta/index' as meta;

.reql-xero-receipt-bank-payment-details-section {
    border: 1px solid #bdb8b8;
    margin: 0 40px 20px 40px;
    padding: 10px 20px 20px 20px;

    &__warning {
        display: flex;
        @include meta.font-standard(12px, #5e5c5c);
    }

    &__warning-text {
        flex: 1;
        overflow: hidden;
        margin-left: 10px;
    }

    &__fields {
        display: flex;
        margin-top: 15px;
    }

    &__field-date {
        width: 140px;
    }

    &__field-account {
        flex: 1;
        overflow: hidden;
    }

    &__field-amount {
        margin-left: 20px;
        text-align: right;
    }

    &__field-value {
        @include meta.font-standard(13px, #000);
    }
}
