@use '../../styles/meta/index' as meta;

.ui-confirmation-dropdown {
    position: relative;
    z-index: 1002;

    &__confirmation-panel {
        display: flex;
        flex-flow: column;
        min-width: 230px;
        max-width: 350px;
        font-size: 14px;
        @include meta.o-dropdown-panel();

        & {
            margin-top: 8px;
        }
    }

    &__confirmation-panel-title {
        margin: 16px 30px 8px;
        @include meta.font-standard(14px, #333);
    }

    &__confirmation-panel-btn-list {
        display: flex;
        justify-content: flex-end;
        margin: 12px 20px;
    }

    &__confirmation-panel-btn {
        margin: 0 8px;
        text-transform: uppercase;
        cursor: pointer;
        @include meta.font-standard(14px, #333);

        &:hover {
            color: #cb4e30;
        }

        &--default {
            @include meta.font-standard-semibold();
        }
    }
}
