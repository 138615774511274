@use 'modules/styles/meta/index' as meta;

.wfc-cache-management-panel {
    cursor: default;
    padding-bottom: 10px;
    min-width: 510px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        @include meta.font-standard-semibold(13px, #000);
    }

    &__header-title {
        display: flex;
        align-items: center;
    }

    &__header-title-logo {
        margin: 0 10px 0 16px;
    }

    &__header-button {
        min-width: 140px;
        padding: 0 20px;
    }

    &__table-header {
        display: flex;
        align-items: center;
        height: 40px;
        background: #f9f9f9;
    }

    &__table-header-col {
        flex: 1 0 0;
        padding: 0 20px;
        @include meta.font-standard-bold(11px, #30373d);

        &:not(:last-child) {
            padding-right: 0;
        }

        &--last-sync {
            min-width: 130px;
        }
    }

    &__table-row {
        display: flex;
        align-items: center;
        height: 40px;
        color: #000;

        &--in-progress {
            color: #777777;
        }
    }

    &__table-cell {
        flex: 1 0 0;
        padding: 0 20px;
        @include meta.font-standard(11px);

        &:not(:last-child) {
            padding-right: 0;
        }

        &--last-sync {
            min-width: 130px;
        }
    }

    &__cell-loading {
        height: 4px;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: #ddd;

        @keyframes wfc-animations-row-loading {
            from {
                left: -100px;
                width: 60%;
            }
            50% {
                width: 30%;
            }
            70% {
                width: 70%;
            }
            80% {
                left: 50%;
            }
            95% {
                left: 120%;
            }
            to {
                left: 100%;
            }
        }

        &:before {
            display: block;
            position: absolute;
            content: '';
            left: -130px;
            width: 130px;
            height: 4px;
            background-color: #77aa84;
            animation: wfc-animations-row-loading 2s linear infinite;
        }
    }
}
