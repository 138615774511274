@mixin u-transparent-input {
    border: none;
    padding: 0;
    background-color: transparent;
}

@mixin u-text-ellipsis {
    & {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@mixin u-posAbsolute($left: null, $top: null, $right: null, $bottom: null, $width: null, $height: null) {
    position: absolute;
    left: $left;
    top: $top;
    right: $right;
    bottom: $bottom;
    height: $height;
    width: $width;
}

@mixin u-posAbsoluteFill($left: 0, $top: 0, $right: 0, $bottom: 0) {
    @include u-posAbsolute($left: $left, $top: $top, $right: $right, $bottom: $bottom);
}

@mixin u-posFixed($left: null, $top: null, $right: null, $bottom: null, $width: null, $height: null) {
    position: fixed;
    left: $left;
    top: $top;
    right: $right;
    bottom: $bottom;
    height: $height;
    width: $width;
}

@mixin u-flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin u-text-static() {
    user-select: none;
}

@mixin u-hiddenVisually {
    border: 0;
    overflow: hidden;
    margin: -1px;
    padding: 0;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    @include u-posAbsolute($width: 1px, $height: 1px);
}
