@use 'sass:color';
@use '../../styles/meta/index';

$whiteCircleShadow: inset 0 0 0 3px #fff;
$regularFocusShadow: 0 0 1px 2px color.adjust(#4c8a5c, $lightness: 30%);
$disabledFocusShadow: 0 0 1px 1px #cacccd;

.ui-radiobox {
    flex-shrink: 0;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: relative;
    border: 2px solid #4c8a5c;
    background: #fff;
    cursor: pointer;

    &:focus {
        box-shadow: $regularFocusShadow;
    }

    &--checked {
        background: #4c8a5c;
        box-shadow: $whiteCircleShadow;
    }

    &--checked:focus {
        box-shadow: $whiteCircleShadow, $regularFocusShadow;
    }

    &--disabled {
        cursor: default;
        background: #fff;
        border-color: #cacccd;
        box-shadow: $whiteCircleShadow;
    }

    &--disabled:focus {
        box-shadow: $whiteCircleShadow, $disabledFocusShadow;
    }

    &--disabled#{&}--checked {
        background: #cacccd;
    }
}
