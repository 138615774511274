@use 'modules/styles/meta/index' as meta;

.page-contact-support-popup {
    width: 800px;

    &__content {
        display: flex;
        flex-flow: column;
        position: relative;
        overflow-y: auto;
        @include meta.o-popup-padding;
    }

    &__field {
        margin-bottom: 30px;
    }

    &__files-section {
        display: flex;
        flex-flow: column;
    }

    &__files-header {
        text-transform: uppercase;
        margin-bottom: 10px;
        @include meta.font-standard-semibold(13px, #000);
    }

    &__files-list {
        display: flex;
        flex-flow: column;

        & > :not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__attachment-item {
        display: flex;
        align-items: center;
        height: 36px;
        border: 1px solid #cacccd;
        padding: 0 7px 0 10px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        @include meta.o-transparent-link;

        &:hover {
            background: #f3f3f3;
        }
    }

    &__attachment-item-icon {
        fill: currentColor;
        color: #a0a1a1;
        margin-right: 5px;
        margin-top: 1px;
    }

    &__attachment-item:hover &__attachment-item-icon {
        color: #4c8a5c;
    }

    &__attachment-item-text {
        margin-right: 5px;
        flex: 1;
        @include meta.font-standard(13px, #000);
        @include meta.u-text-ellipsis;
    }

    &__attachment-item:hover &__attachment-item-text {
        color: #477753;
    }

    &__attachment-item-delete-button {
        fill: currentColor;
        color: #cacccd;
        margin: 5px;
    }

    &__attachment-item:hover &__attachment-item-delete-button {
        color: #384650;
    }

    &__files-add-new-button {
        margin-top: 10px;
        display: flex;
        height: 36px;
        color: #477753;
    }

    &__files-add-new-content {
        display: flex;
        align-items: center;
        padding: 10px;
        @include meta.font-standard-semibold(13px, currentColor);
    }

    &__files-add-new-icon {
        fill: #4c8a5c;
        margin-right: 6px;
    }

    &__drop-zone {
        padding: 30px 60px;
        background: #fff;
        z-index: 10;
        user-select: none;
        display: none;
        border-top: 1px solid #e4e4e4;
        color: #a0a1a1;
        @include meta.u-posAbsolute($left: 0, $right: 0, $bottom: 0, $top: 0);

        &--global {
            display: flex;
        }

        &--local {
            color: #4c8a5c;
        }
    }

    &__drop-content {
        flex: 1;
        flex-flow: column;
        border: 2px dashed #a0a1a1;
        border-radius: 5px;
        @include meta.u-flexCenter;
    }

    &__drop-zone--active &__drop-content {
        border-color: #4c8a5c;
    }

    &__drop-icon {
        fill: currentColor;
        margin-bottom: 5px;
    }

    &__drop-text {
        @include meta.font-standard-semibold(14px);
    }
}
