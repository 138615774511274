@use 'modules/styles/meta/index';

$side-margin: 60px;

.reql-line-items-section {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;

    &:first-child {
        margin-bottom: 10px;
    }
}
