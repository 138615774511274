@use 'modules/styles/meta/index' as meta;

.automation-workflow-section {
    display: flex;
    flex-flow: column;

    &__tabs {
        display: flex;
        margin-bottom: 10px;
    }

    &__tab-item {
        padding-bottom: 4px;
        cursor: pointer;
        margin-right: 10px;

        &--active {
            border-bottom: 2px solid meta.$color-forest-green;
        }
    }

    &__tab-panel {
        flex: 1;
        display: none;
        flex-flow: column;

        &--active {
            display: flex;
        }
    }

    &__address {
        display: flex;
        margin: 0 -5px 10px -5px;
    }

    &__address-field {
        flex: 1;
        margin: 0 5px;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
    }

    &__log-area {
        margin-top: 10px;
        border: 1px solid meta.$color-forest-green;
        overflow-y: auto;
        flex: 1;
        cursor: auto;
        user-select: text;
    }

    &__log-entry {
        &--error {
            color: darkred;
        }

        &--warning {
            color: darkkhaki;
        }
    }

    &__log-footer {
        display: flex;
    }

    &__counter {
        margin-right: 10px;
        margin-top: 3px;
        cursor: pointer;
        border-bottom: 1px dashed meta.$color-forest-green;

        &--selected {
            color: meta.$color-forest-green;
        }
    }

    &__restore-block {
        display: flex;
        align-items: center;
        margin-top: 30px;

        & > :not(:last-child) {
            margin-right: 15px;
        }

        & > :nth-child(2) {
            flex: 1;
        }
    }
}
