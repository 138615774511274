@use 'modules/styles/meta/index';

$side-margin: 60px;

.reql-attachments-section {
    display: flex;
    padding: 0 $side-margin - 10px 0 $side-margin;

    & > * {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    &__content {
        flex: 0.5;
    }

    .reql-section-container__body {
        display: flex;
    }
}
