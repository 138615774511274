@use '../../../styles/meta/index' as meta;

%form-text-editor {
    text-overflow: ellipsis;
}

.form-text-editor--t-form {
    @extend %form-text-editor;
    @include meta.o-form-input();

    &:focus:not(&--invalid) {
        color: #384650;
    }

    &:disabled {
        user-select: none;
        pointer-events: none;
    }
}

.form-text-editor--t-transparent {
    @extend %form-text-editor;
    @include meta.u-transparent-input;

    &--invalid::placeholder {
        color: #923924;
    }
}

.form-text-editor--t-auth {
    padding: 5px;
    @extend %form-text-editor;
    @include meta.o-form-input();
    & {
        @include meta.font-standard(14px, #000);
    }

    &:focus:not(&--invalid) {
        color: #384650;
    }

    &:disabled {
        user-select: none;
        pointer-events: none;
    }
}
