@use 'modules/styles/meta/index' as meta;

.app-licensing-notification {
    width: 100%;
    background: #384650;
    padding-left: 0;

    & > :not(:last-child) {
        border-bottom: 1px solid #767280;
    }

    &__contact-button {
        text-transform: uppercase;
        text-decoration: none;
        @include meta.font-standard(12px, #fff);

        &:link,
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
            color: #fff;
        }
    }

    &__panel-wrp {
        display: flex;
        justify-content: center;
        transition: 0.3s;
    }

    &--drawer-pinned &__panel-wrp {
        transition: 0s;

        @media screen and (max-width: 760px) {
            padding-left: 0;
        }
    }

    &__trial-panel {
        width: 100%;
        min-height: 40px;
        max-height: 300px;
        display: flex;
        align-items: center;
    }

    &__trial-message {
        flex: 1;
        overflow: hidden;
        height: 100%;
        padding: 10px 20px 10px 0;
        @include meta.font-standard(13px, #fff);
    }

    &__trial-actions {
        flex: none;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__grace-panel {
        width: 100%;
        min-height: 40px;
        max-height: 300px;
        display: flex;
        align-items: center;
    }

    &__grace-message {
        flex: 1;
        overflow: hidden;
        height: 100%;
        padding: 10px 20px 10px 0;
        @include meta.font-standard(13px, #fff);
    }

    &__grace-actions {
        flex: none;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__grace-myaccount-button {
        margin-left: 20px;
        background: meta.$color-terra-cotta;
        padding: 7px 23px;
        text-transform: uppercase;
        text-decoration: none;
        @include meta.font-standard-bold(12px, #fff);

        &:link,
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
            color: #fff;
        }
    }

    &__expired-panel {
        width: 100%;
        min-height: 40px;
        max-height: 300px;
        display: flex;
        align-items: center;
    }

    &__expired-message {
        flex: 1;
        overflow: hidden;
        height: 100%;
        padding: 10px 20px 10px 0;
        @include meta.font-standard(13px, #fff);
    }

    &__expired-actions {
        flex: none;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__actions-myaccount-button {
        margin-left: 20px;
        border: 1px solid #87c797;
        padding: 2px 12px;
        text-transform: uppercase;
        text-decoration: none;
        @include meta.font-standard-bold(12px, #87c797);

        &:link,
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
            color: #87c797;
        }
    }

    &__grace-panel,
    &__trial-panel,
    &__expired-panel {
        max-width: initial;
        padding: 0 60px;
    }
}
