@use 'modules/styles/meta/index' as meta;

.reqf-integration-error-popup {
    width: 640px;
    padding: 30px 60px 30px 170px;
    position: relative;

    &__icon {
        @include meta.u-posAbsolute($left: 60px, $top: 40px);
    }

    &__title {
        @include meta.font-standard-semibold(20px, #333);
        @include meta.u-text-ellipsis();
    }

    &__description {
        margin-top: 20px;
        @include meta.font-standard(14px);
    }

    &__close-button-wrp {
        margin-top: 40px;
        text-align: right;
    }
}
