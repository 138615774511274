@use '../../../styles/meta/index' as meta;

.form-password-editor--t-form {
    display: flex;
    align-items: center;
    @include meta.o-form-input;

    &__editor {
        flex: 1;
    }

    &__eye {
        margin: 0 3px;
        display: flex;
        align-items: center;
    }

    &__eye-on {
        padding-top: 4px;
    }
}

.form-password-editor--t-auth {
    display: flex;
    align-items: center;
    padding: 5px;
    @include meta.o-form-input;
    & {
        @include meta.font-standard(14px, #000);
    }

    &__editor {
        flex: 1;
    }

    &__eye {
        margin: 0 3px;
        display: flex;
        align-items: center;
    }

    &__eye-on {
        padding-top: 4px;
    }
}
