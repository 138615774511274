@use 'modules/styles/meta/index' as meta;

$side-margin: 60px;

.reql-xero-pending-status-toolbar-section {
    display: flex;
    align-items: center;
    padding: 19px $side-margin 19px $side-margin;
    background: #f7f7f7;

    &__icon {
        margin-right: 15px;
    }

    &__text {
        flex: 1;
        @include meta.font-standard(13px, #737373);
    }
}
