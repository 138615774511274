@use 'modules/styles/meta/index' as meta;

.common-ui-document-preview-panel {
    width: 100%;
    height: 100%;

    &__content-image {
        height: 100%;
        padding: 10px 20px;
        text-align: center;
        white-space: nowrap;
        overflow: auto;
        &::before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -0.25em;
        }

        &--hidden {
            visibility: hidden;
        }
    }

    &__content-image-img {
        display: inline-block;
        width: auto;
        height: auto;

        &:not(&--zoomed) {
            cursor: zoom-in;
            width: 60%;
            height: auto;
        }

        &--zoomed {
            cursor: zoom-out;
        }
    }

    &__content-pdf {
        width: 100%;
        height: 100%;
    }

    &__content-pdf-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: hidden;
    }

    &__content-unknown {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content-unknown-card {
        max-width: 450px;
        padding: 40px 60px;
        background: #fff;
        display: flex;
        flex-flow: column;
    }

    &__content-unknown-card-title {
        margin-bottom: 20px;
        text-align: center;
        @include meta.font-standard-semibold(18px, #333);
    }

    &__content-unknown-card-description {
        margin-bottom: 20px;
        text-align: center;
        @include meta.font-standard(13px, #777);
    }

    &__content-unknown-card-download-link {
        align-self: center;
        @include meta.o-transparent-link();
    }

    &__content-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        @include meta.u-posAbsoluteFill();
    }
}
