@use '../../styles/meta/index' as meta;

.ui-action-menu-text-button {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    &__dropdown-text {
        margin-right: 4px;
    }

    &__dropdown-icon {
        transform: rotate(180deg);
        fill: meta.$color-forest-green;
    }
}
