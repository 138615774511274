@use '../../styles/meta/index' as meta;
@use 'index' as index;

.ui-data-table-default-column-header {
    display: flex;
    align-items: center;
    fill: currentColor;
    user-select: none;
    cursor: default;
    @include meta.font-standard-semibold(13px, #565656);
    @include index.data-row-cell-padding();

    &--sortable {
        cursor: pointer;

        &:hover {
            color: #4d9a4d;
        }
    }

    &__text {
        text-transform: uppercase;
        margin-right: 4px;
    }

    &__sort-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        opacity: 1;
        transition: all 300ms ease-in-out;

        &--desc {
            transform: rotate(0);
        }

        &--hidden {
            opacity: 0;
        }
    }
}
