@use 'modules/styles/meta/index' as meta;

.reql-toolbar-integration-header {
    flex: 1;
    display: flex;
    align-items: center;
    user-select: none;
    overflow: hidden;
    &__source {
        word-break: break-word;
        @include meta.font-standard(12px, #777d81);
    }

    &__requested-by-block {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        margin-left: 5px;
    }

    &__company-name {
        margin-top: 1px;
        text-transform: uppercase;
        @include meta.font-standard(11px, #000);
        @include meta.u-text-ellipsis();
    }

    &__avatar {
        flex: none;
        cursor: pointer;

        &--system {
            border-radius: 50%;
            overflow: hidden;
            cursor: default;
        }
    }
}
