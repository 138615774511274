@use 'modules/styles/meta/index' as meta;

.automation-dev-tools-popup {
    width: 800px;
    height: 600px;

    &__content {
        flex: 1;
        overflow: hidden;
        display: flex;
        padding: 0 60px 40px 0;
    }

    &__menu {
        display: flex;
        flex-flow: column;
        padding: 0 0 0 33px;
        width: 170px;
        @include meta.o-popup-border-right();
    }

    &__menu-item {
        padding: 3px 3px 3px 8px;
        margin: 0 20px 10px 0;
        cursor: pointer;
        border: 1px solid transparent;
        @include meta.font-standard(15px, meta.$color-forest-green);

        &--selected {
            border-color: meta.$color-forest-green;
        }
    }

    &__section {
        flex: 1;
        overflow: hidden;
        padding-left: 40px;
        display: flex;
        flex-flow: column;
    }

    &__section-content {
        flex: 1;
        overflow: hidden;
    }
}
