@use 'sass:color';
@use 'modules/styles/meta/index' as meta;

.first-start-connect-to-panel {
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        margin-top: 15px;
    }

    &__tile {
        position: relative;
        padding: 20px;
        border: 1px solid #dfdfdf;
        font-size: 12px;
    }

    &__tile-loading-bar {
        opacity: 0;
        transition: opacity 300ms ease-in-out;
        @include meta.u-posAbsolute($left: 0, $top: 0, $right: 0);

        &--visible {
            opacity: 1;
        }
    }

    &__tile-content {
        flex: 1;
        display: flex;
        flex-flow: column;
    }

    &__tile-title {
        display: flex;
        align-items: center;
    }

    &__tile-image {
        margin-right: 12px;
    }

    &__tile-title-text {
        overflow: hidden;
        flex: 1;
        margin-right: 12px;
        @include meta.font-standard-semibold(14px, #000);
    }

    &__tile-description {
        margin-top: 18px;
        margin-bottom: 4px;
        @include meta.font-standard(12px, #000);
    }

    &__qbo-connect-button {
        width: 105px;
        height: 30px;
        background: #34aa27;
        border-radius: 2px;
        padding-right: 5px;
        cursor: pointer;
        @include meta.u-flexCenter();

        &:not(:disabled):hover {
            background-color: color.adjust(#34aa27, $lightness: -8%);
        }

        &:disabled {
            opacity: 0.8;
            cursor: default;
        }
    }
}
