@use 'modules/styles/meta/index' as meta;

.automation-company-section {
    display: flex;
    flex-flow: column;

    &__table {
        flex: 1;
        overflow: hidden;
    }

    &__toolbar {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
}
