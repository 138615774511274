@use 'modules/styles/meta/index' as meta;

.company-select-subscription-gray-area {
    background: #f3f3f3;
    margin-bottom: 30px;
    padding: 15px 20px;
    @include meta.font-standard(13px, #000);

    &__header {
        margin-bottom: 5px;
        @include meta.font-standard-semibold(13px, #000);
    }

    &__text {
        @include meta.font-standard(12px, #000);
    }
}
