@use '../../styles/meta/index' as meta;

.ui-action-menu-item {
    padding: 3px 20px;
    font-weight: 400;
    color: #333;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    display: block;
    width: 100%;
    text-align: left;

    &:disabled {
        @include meta.o-disabled();
    }

    &:not(:disabled):hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
    }
}
