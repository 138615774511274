@use '../../../styles/meta/index';

%form-text-area-editor {
    display: block;
    width: 100%;
    resize: none;
}

.form-text-area-editor--t-form {
    @extend %form-text-area-editor;
    color: #000;
    border-radius: 2px;
    border: 1px solid #cacccd;
    padding: 5px;
    overflow-y: auto;

    &:focus {
        color: #384650;
        border-color: #565656;
    }

    &:disabled {
        color: #70787f;
        cursor: default;
        user-select: none;
        pointer-events: none;
    }

    &--invalid {
        background-color: #fff5f3;
        border-color: #f99178;
        color: #923924;
    }
}

.form-text-area-editor--t-transparent {
    @extend %form-text-area-editor;
    border: none;
    padding: 0;
    background-color: transparent;
    overflow-y: auto;

    &--invalid::placeholder {
        color: #923924;
    }
}
