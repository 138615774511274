@use 'modules/styles/meta/index' as meta;

.rpt-paginator {
    display: flex;
    align-items: center;

    &__prev-next-button {
        background: #6ba67a;
        padding: 5px 15px;
        cursor: pointer;
        @include meta.font-standard-semibold(12px, #fff);

        &--disabled {
            background: #f1f0f0;
            color: #a0a1a1;
            cursor: default;
        }

        &:not(&--disabled):hover {
            background: #397f4a;
        }

        &:not(&--disabled):focus {
            background: #397f4a;
            color: rgba(255, 255, 255, 0.6);
        }
    }

    &__buttons-section {
        margin: 0 10px;
        display: flex;
    }

    &__page-button {
        background: #f1f1f1;
        padding: 5px 10px;
        cursor: pointer;
        @include meta.font-standard-semibold(12px, #30373d);

        &--active {
            background: #64a174;
            color: #fff;
            cursor: default;
        }

        &:not(&--active):hover {
            background: #cacccd;
        }
    }

    &__page-ellipsis {
        padding: 5px 5px;
        @include meta.font-standard-semibold(12px, #30373d);
    }
}
