@use '../../../../styles/meta/index' as meta;

.ui-default-popup-content-button {
    max-width: 100%;
    text-transform: uppercase;
    transition: color 200ms;
    cursor: pointer;
    user-select: none;
    @include meta.font-standard-semibold(16px, #4c8a5c);
    @include meta.u-text-ellipsis;

    &:disabled {
        @include meta.o-disabled();
    }

    &:not(:disabled):hover {
        color: #286538;
    }
}
