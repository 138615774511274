@use 'modules/styles/meta/index' as meta;

.common-ui-password-validation-section {
    display: flex;
    flex-flow: wrap;

    &--vertical {
        flex-direction: column;
    }

    &__password-validation-item {
        display: flex;
        margin-bottom: 10px;
        @include meta.font-standard(12px, #565656);

        &--pass {
            color: #477753;
        }

        &--invalid {
            color: #923924;
        }
    }

    &--horizontal &__password-validation-item {
        width: calc(50% - 10px);

        &:not(:nth-child(even)) {
            margin-right: 20px;
        }
    }

    &__password-validation-text {
        flex: 1;
        margin-left: 5px;
    }
}
