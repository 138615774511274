@use '../../styles/meta/index' as meta;

.ui-toolbar-button {
    cursor: pointer;
    user-select: none;
    width: 50px;
    height: 50px;
    padding: 10px;
    color: #4c8b5d;
    fill: currentColor;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: background 0.1s ease-in;

    &:disabled {
        color: #ccc7c7;
        @include meta.o-disabled();
    }

    &:not(:disabled) {
        &:hover,
        &:focus {
            color: #2a5844;
            background: #eee;
        }
    }

    &--dark {
        color: #384650;

        &:not(:disabled) {
            &:hover,
            &:focus {
                color: #384650;
                background: #eee;
            }
        }
    }

    &--small {
        width: 36px;
        height: 36px;
        padding: 5px;
    }

    &--auto {
        width: auto;
        height: 36px;
        padding: 5px;
    }
}
