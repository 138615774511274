@use 'modules/styles/meta/index' as meta;

$side-margin: 60px;

.reql-section-container {
    padding: 0 $side-margin;
    display: flex;
    flex-flow: column;

    &--no-padding {
        padding: 0;
    }

    &__header {
        flex: none;
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;
        @include meta.font-standard-semibold(14px, #000);
    }

    &__body {
        overflow: hidden;
    }
}
