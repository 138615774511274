@forward 'utils';
@forward 'animations';
@forward 'objects';
@forward 'colors';
@forward 'adaptive';

@mixin font-standard($size: null, $color: null) {
    font-family: 'Open Sans', sans-serif, Arial;
    font-weight: 400; // =normal
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}

@mixin font-standard-semibold($size: null, $color: null) {
    font-family: 'Open Sans', sans-serif, Arial;
    font-weight: 600;
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}

@mixin font-standard-bold($size: null, $color: null) {
    font-family: 'Open Sans', sans-serif, Arial;
    font-weight: 700; // =bold
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}

@mixin font-standard-light($size: null, $color: null) {
    font-family: 'Open Sans', sans-serif, Arial;
    font-weight: 300; // =light
    @if ($size) {
        font-size: $size;
    }
    @if ($color) {
        color: $color;
    }
}
