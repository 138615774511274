@use 'sass:color';
@use '../../styles/meta/index' as meta;

@mixin standardButton() {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s;
    cursor: pointer;
    user-select: none;
    @include meta.u-text-ellipsis;

    &:disabled {
        @include meta.o-disabled();
    }

    &:focus {
        @include meta.o-focus;
    }

    &--rounded {
        border-radius: 4px;
    }
}

@mixin addPrimaryColorTheme($colorThemeName, $backgroundColor, $color: null, $disabledBackgroundColor: null) {
    @if ($disabledBackgroundColor) {
        &--color-#{$colorThemeName}:disabled {
            opacity: 1;
            background: $disabledBackgroundColor;
        }
    }

    &--color-#{$colorThemeName} {
        background: $backgroundColor;
        @if ($color) {
            color: $color;
        }
    }

    &--color-#{$colorThemeName}:hover:not(:disabled) {
        background: color.adjust($backgroundColor, $lightness: -8%);
        @if ($color) {
            color: $color;
        }
    }

    &--color-#{$colorThemeName}:active:not(:disabled) {
        background: color.adjust($backgroundColor, $lightness: -14%);
        @if ($color) {
            color: rgba($color, 0.6);
        }
    }
}

@mixin addSecondaryColorTheme($colorThemeName, $color) {
    &--color-#{$colorThemeName} {
        border: 1px solid $color;
        color: $color;
    }

    &--color-#{$colorThemeName}:hover:not(:disabled) {
        $hoverColor: color.adjust($color, $lightness: -8%);
        border-color: $hoverColor;
        color: $hoverColor;
    }

    &--color-#{$colorThemeName}:active:not(:disabled) {
        $pressedColor: color.adjust($color, $lightness: -14%);
        border-color: $pressedColor;
        color: $pressedColor;
    }
}

.ui-standard-button--t-primary {
    @include standardButton();

    @include addPrimaryColorTheme('brand', #4c8a5c, #fff, #cfe1d3);
    @include addPrimaryColorTheme('aqua', #19b9e2, #fff);
    @include addPrimaryColorTheme('xero-new', #003c64, #fff);
    @include addPrimaryColorTheme('receipt-bank', #f25929, #fff);
    @include addPrimaryColorTheme('airwallex', #eb654a, #fff, #cacccd);
    @include addPrimaryColorTheme('red', #ca5c41, #fff);
    @include addPrimaryColorTheme('grey', #eff0f0, #384650);
    @include addPrimaryColorTheme('white', #fff, #4c8a5c);
    @include addPrimaryColorTheme('purple', #7b53b2, #fff);
    @include addPrimaryColorTheme('black', #000, #fff);
}

.ui-standard-button--t-secondary {
    @include standardButton();

    @include addSecondaryColorTheme('brand', #4c8a5c);
    @include addSecondaryColorTheme('aqua', #128daa);
    @include addSecondaryColorTheme('xero-new', #003c64);
    @include addSecondaryColorTheme('receipt-bank', #f25929);
    @include addSecondaryColorTheme('airwallex', #eb654a);
    @include addSecondaryColorTheme('red', #ca5c41);
    @include addSecondaryColorTheme('grey', #777d81);
    @include addSecondaryColorTheme('white', #fff);
    @include addSecondaryColorTheme('purple', #7b53b2);
    @include addSecondaryColorTheme('black', #000);
}
