@use '../../styles/meta/index' as meta;

.ui-raw-avatar {
    overflow: hidden;
    border-radius: 50%;
    vertical-align: middle;
    background: #fff no-repeat 50% 50%;
    background-size: cover;

    &--abbreviation {
        text-transform: uppercase;
        text-align: center;
        background-color: #e8e8e8;
        @include meta.font-standard(12px, #777d81);
    }

    &--image {
    }
}
