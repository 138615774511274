@use '../../../styles/meta/index' as meta;

@mixin form-date-time-editor {
    &__button {
        position: relative;
    }

    &__button-input {
        @include meta.u-transparent-input();
        width: 100%;
        line-height: 19px;
        cursor: pointer;
        border: solid 1px #cacccd;
        border-radius: 2px;
        padding: 3px;

        &:focus {
            border: solid 1px #565656;
            cursor: auto;
        }

        &:disabled {
            cursor: default;
            color: #a0a1a1;
            background: #f8f5f5;
        }
    }

    &--invalid &__button-input {
        background-color: #fff5f3;
        border-color: #f99178;
        color: #923924;

        &::placeholder {
            color: #923924;
        }
    }

    &--warning &__button-input {
        background-color: #fff8e1;
        border-color: #ffc107;
        color: #ffc107;

        &::placeholder {
            color: #ffc107;
        }
    }

    &__clear-button {
        @include meta.u-posAbsolute($right: 6px, $top: 50%);
        translate: 0 -50%;
        fill: #eaeaea;
        background: #a3a2a2;
        transition:
            opacity 150ms,
            background-color 150ms,
            visibility 200ms;
        opacity: 0;
        border: 0;
        border-radius: 50%;
        padding: 3px;
        line-height: 0;

        &--hidden {
            display: none;
        }

        &:hover {
            background: #ee6f3a;
            fill: #fff;
        }
    }

    &__button:hover &__clear-button {
        opacity: 1;
    }

    &__button--disabled &__clear-button {
        display: none;
    }

    &__panel {
        display: flex;
        align-items: flex-start;
        min-height: 341px;

        .drop-dropdown-position-inverted & {
            align-items: flex-end;
        }
    }

    &__day-picker {
        @include meta.o-dropdown-panel();
    }

    &__mobile {
        width: 100%;
        height: 26px;
        line-height: inherit !important; // overrides bootstrap style
        cursor: pointer;
        border-radius: 2px;
        @include meta.u-transparent-input();
        & {
            border: solid 1px #cacccd;
            padding: 3px;
        }

        &:focus {
            border: solid 1px #565656;
            cursor: auto;
        }

        &:disabled {
            color: #a0a1a1;
            background: #f8f5f5;
        }

        &--invalid {
            background-color: #fff5f3;
            border-color: #f99178;
            color: #923924;

            &::placeholder {
                color: #923924;
            }
        }

        &--warning {
            background-color: #fff8e1;
            border-color: #ffc107;
            color: #ffc107;

            &::placeholder {
                color: #ffc107;
            }
        }
    }
}

.form-date-time-editor--t-form {
    @include form-date-time-editor();
}

.form-date-time-editor--t-transparent {
    @include form-date-time-editor();

    &__button-input {
        border: none;
        padding: 0;
        background-color: transparent;

        &:focus {
            border: none;
        }
    }
}
